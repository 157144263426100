import {
  colors,
  EmotionStyles,
  type,
  variables,
} from "@interactive-investor/onestack-web-shared";

export const styles: EmotionStyles = {
  settingsSection: {
    margin: `${variables.marginMd} 0`,
  },

  backToHomepage: {
    fontSize: type.textSmall.fontSize,
    marginTop: variables.marginSm,
  },

  iconLeft: {
    marginRight: variables.marginSm,
  },

  iconRight: {
    marginLeft: variables.marginLg,
  },

  updateMessageContainer: {
    marginTop: variables.marginXxl,
  },

  updateMessage: {
    background: colors.success,
    borderRadius: "3px",
    color: colors.white,
    padding: variables.paddingSm,
  },
};
