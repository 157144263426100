import classnames from "classnames";
import React from "react";
import * as validator from "validator";

import {
  Button,
  Icon,
  Input,
  OneSiteLink,
} from "@interactive-investor/onestack-web-shared";

import { withAuth } from "../../contexts/Auth";
import { AuthContextInjectedProps } from "../../contexts/Auth/interfaces/context";
import { ApiError } from "../../services/api/interfaces/apiError";
import ProfileService from "../../services/profile";
import { ProfileInterface } from "../../services/profile/interfaces/profile";

import { SettingsContainerState } from "./interfaces/state";

import { styles } from "./styles";

class SettingsContainer extends React.Component<
  AuthContextInjectedProps,
  SettingsContainerState
> {
  accessToken = this.props.auth.getAccessTokenFree() as string;

  constructor(props: AuthContextInjectedProps) {
    super(props);

    this.state = {
      processing: false,
      profile: undefined,
      profileFieldErrors: {
        email: undefined,
        password: undefined,
      },
      showSubmit: false,
    };
  }

  validateProfileField = (key: string, value: string): boolean => {
    switch (key) {
      case "email":
        if (validator.isEmail(value)) {
          this.updateProfileFieldError("email", undefined);
          return true;
        }

        this.updateProfileFieldError("email", `${value} is not a valid email`);
        return false;

      default:
        return false;
    }
  };

  updateProfileField = (key: string, value: string) => {
    this.setState({
      profile: {
        ...this.state.profile,
        [key]: value,
      } as ProfileInterface,
      showSubmit: true,
      success: undefined,
    });
  };

  updateProfileFieldError = (key: string, value: string | undefined) => {
    this.setState({
      profileFieldErrors: {
        ...this.state.profileFieldErrors,
        [key]: value,
      },
    });
  };

  handleGetProfile = (profile: ProfileInterface) =>
    this.setState({
      profile,
    });

  handleGetProfileError = (error: ApiError) => this.setState({ error });

  handleProfileFormSubmit = () => {
    const updatedProfile: any = {};
    let validated = true;

    if (!this.state.profile) {
      return;
    }

    validated = this.validateProfileField("email", this.state.profile.email);
    updatedProfile.email = this.state.profile.email;

    if (validated) {
      this.setState({
        error: undefined,
        processing: true,
        success: undefined,
      });

      ProfileService.updateProfile(this.state.profile, this.accessToken)
        .then(() =>
          this.setState({
            processing: false,
            showSubmit: false,
            success: (
              <React.Fragment>
                <p
                  css={styles.updateMessageContainer}
                  className={classnames("text-success")}
                >
                  <span css={styles.updateMessage}>
                    <Icon
                      identifier="check"
                      cssStyles={[styles.iconLeft]}
                      set="far"
                    />
                    your email address has been updated
                  </span>
                </p>
                <p className="text-success">now you will need to:</p>
                <p className="text-success">
                  <strong>verify your email</strong> - we have sent an email to
                  the new address with instructions
                </p>
              </React.Fragment>
            ),
          })
        )
        .catch((error: any) =>
          this.setState({
            error: {
              data: error.error,
              message: error.message,
              status: error.status,
            },
            processing: false,
          })
        );
    }
  };

  componentDidMount() {
    ProfileService.getProfile(this.accessToken)
      .then((profile: ProfileInterface) => this.handleGetProfile(profile))
      .catch((error: any) =>
        this.handleGetProfileError({
          data: error.error,
          message: error.message,
          status: error.status,
        })
      );
  }

  componentWillUnmount() {
    this.handleGetProfile = (profile: ProfileInterface) => undefined;
    this.handleGetProfileError = (error: ApiError) => undefined;
  }

  render() {
    const {
      showSubmit,
      profileFieldErrors,
      profile,
      error,
      success,
      passwordChangeNotification,
    } = this.state;

    return (
      <div css={styles.container} className="GridRow">
        <div className="GridCell-xs-auto GridCell-offset-md-1">
          <h1>Account settings</h1>

          <form onSubmit={(e) => e.preventDefault()}>
            <p>
              You can update your email, password and newsletter preferences
              below.
            </p>

            {profile && (
              <React.Fragment>
                <div className="GridRow GridRow--collapse-gutters">
                  <div className="GridCell-xs-auto GridCell-md-9 GridCell-lg-6">
                    <Input
                      name="email"
                      type="email"
                      label="Email address"
                      value={profile.email}
                      onChange={(email: string) =>
                        this.updateProfileField("email", email)
                      }
                      onBlur={() => {
                        if (typeof this.state.profile !== "undefined") {
                          this.validateProfileField(
                            "email",
                            this.state.profile.email
                          );
                        }
                      }}
                      cssStyles={[styles.input_email]}
                      error={profileFieldErrors.email}
                    />
                  </div>
                </div>

                <div css={styles.settingsSection}>
                  <label htmlFor="profilePassword">Log in password: </label>
                  {!passwordChangeNotification && !profileFieldErrors.password && (
                    <span
                      onClick={() => {
                        if (this.state.profile) {
                          this.props.auth.changePassword(
                            this.state.profile.email
                          );
                        }
                      }}
                      className="Link"
                      title={`Change password`}
                      id="profilePassword"
                    >
                      change your password
                    </span>
                  )}
                  {passwordChangeNotification && (
                    <span className="text-success">
                      {passwordChangeNotification}
                    </span>
                  )}
                  {profileFieldErrors.password && (
                    <span className="text-error">
                      {profileFieldErrors.password}
                    </span>
                  )}
                </div>

                <div css={styles.settingsSection}>
                  <label htmlFor="profileNewsletterPreferences">
                    Newsletter Preferences:{" "}
                  </label>
                  <OneSiteLink
                    id="profileNewsletterPreferences"
                    to={`/newsletter-preferences/${profile.federatedId}`}
                    title="Newsletter Preferences"
                  >
                    change your newsletter preferences
                  </OneSiteLink>
                </div>

                {error && (
                  <p className="text-error">
                    <Icon
                      identifier="exclamation-triangle"
                      set="fal"
                      cssStyles={[styles.iconLeft]}
                    />
                    There was a problem getting or saving your profile. Please
                    try again later.
                    <br />
                  </p>
                )}

                {success}

                {showSubmit && (
                  <Button
                    type="primary"
                    size="medium"
                    disabled={this.state.processing}
                    onClick={() => this.handleProfileFormSubmit()}
                  >
                    Save Profile
                  </Button>
                )}
              </React.Fragment>
            )}
          </form>
          <p css={styles.backToHomepage}>
            <OneSiteLink
              to="/"
              title="Return to homepage"
              className="text-lowercase"
            >
              Return to homepage
            </OneSiteLink>
          </p>
        </div>
      </div>
    );
  }
}
export default withAuth(SettingsContainer);
