import { Router } from "@reach/router";
import React from "react";

import SettingsContainer from "../../containers/Settings";
import RequireAuthFreeWrapper, {
  RequireAuthFreeWrapperProps,
} from "../../contexts/Auth/RequireAuthFreeWrapper";

import Layout from "../../components/layout";
import { Route } from "../../helpers/routing";

export interface SettingsPageProps {
  location: any;
}

export interface SettingsPageState {
  mounted: boolean;
}

const Settings = (props: any) => {
  const authWrapperProps = {
    location,
    name: "Settings",
  } as RequireAuthFreeWrapperProps;

  return (
    <RequireAuthFreeWrapper {...authWrapperProps}>
      <SettingsContainer {...(props as any)} />
    </RequireAuthFreeWrapper>
  );
};

class SettingsPage extends React.Component<
  SettingsPageProps,
  SettingsPageState
> {
  constructor(props: SettingsPageProps) {
    super(props);

    this.state = {
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    if (!this.state.mounted) {
      return null;
    }

    return (
      <Layout>
        <Router>
          <Route Component={Settings} path="/settings" />
        </Router>
      </Layout>
    );
  }
}

export default SettingsPage;
