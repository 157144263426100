import { sanitizeInputString } from "@interactive-investor/onestack-web-shared";

import ApiService from "../api";

import { ProfilePOSTPUTInterface } from "./interfaces/profile";

/**
 * Profile
 */

const getProfile = (accessToken: string) =>
  ApiService.get(`free/api/1/research/profile`, undefined, accessToken);

const updateProfile = (profile: ProfilePOSTPUTInterface, accessToken: string) =>
  ApiService.put(
    `free/api/1/research/profile`,
    {
      ...profile,
      email: sanitizeInputString(profile.email),
    } as ProfilePOSTPUTInterface,
    accessToken
  );

const getMarketingPreferences = (federatedId: string) =>
  ApiService.get(`/free/api/2/marketing-preference/${federatedId}`);

/**
 * Create the service and export
 */
const ProfileService = {
  getMarketingPreferences,
  getProfile,
  updateProfile,
};

export default ProfileService;
